import React from "react";

import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

import axios from "axios";

function ClassStrip(props) {
  const handleClick = () => {
    let students = JSON.parse(localStorage.getItem("students"));

    if (students != null) {
      let student = {};

      for (let i = 0; i < students.length; i++) {
        if (students[i].id === props.student) {
          student = students[i];
          break;
        }
      }

      let endpoint =
        "https://aanlyn.nukleus.co.za/server/api/attendance-log/create.php";

      endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

      console.log(student["token"]);

      axios
        .post(endpoint, {
          token: student["token"],
          student_id: student["id"],
          class_id: props.class.class_id,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function joinDate(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  return (
    <Jumbotron className="mb-4 p-4 bg-white shadow rounded">
      <div className="d-flex justify-content-center justify-content-md-between align-items-center flex-wrap">
        <div className="text-center text-md-left">
          <h2 className="mb-2">{props.class.class_title}</h2>

          <p className="text-muted">
            {props.class.subject} - Module {props.class.module}
          </p>
        </div>

        <Button
          variant="outline-success"
          href={props.class.zoom_link}
          className="mt-4 mt-md-0 px-4 rounded-pill w-md-100"
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          KLAS BYWOON
        </Button>
      </div>

      <hr />

      <h5 className="text-center">
        <span className="d-none d-md-inline">Klas Begin: </span>
        {joinDate(
          new Date(props.class.class_start_date),
          [{ day: "numeric" }, { month: "short" }, { year: "numeric" }],
          " "
        )}
        {" - "}
        {("0" + new Date(props.class.class_start_date).getHours()).slice(-2) +
          ":" +
          ("0" + new Date(props.class.class_start_date).getMinutes()).slice(-2)}
      </h5>
    </Jumbotron>
  );
}

export default ClassStrip;
