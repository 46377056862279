import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import NavigationBar from "../components/NavigationBar";
import BookCard from "../components/BookCard";

import axios from "axios";

function BookLibraryScreen(props) {
  const subjects = [
    "Afrikaans",
    "Engels",
    "Wiskunde",
    "Wiskunde Geletterdheid",
    "Lewensoriëntering",
    "Fisiese Wetenskap",
    "Lewenswetenskap",
    "Besigheidstudies",
    "Verbruikerstudies",
    "Skrif",
    "Natuurwetenskappe en Tegnologie",
    "Kuns & Kultuur",
    "Natuurwetenskappe",
    "Tegnologie",
    "Ekonomiese en Bestuurswetenskappe",
    "Landbouwetenskappe",
    "Landboubestuurspraktyk",
    "Toerisme",
    "Inligtingstegnologie",
    "Rekeningkunde",
    "Geografie",
    "Ekonomie",
    "Rekenaar Toepassingstegnologie",
    "IGO",
    "Voorgeskrewe Boeke",
    "Geskiedenis",
  ].sort();

  const [subjectFilter, setSubjectFilter] = useState("");
  const [moduleFilter, setModuleFilter] = useState(0);

  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [settings, setSettings] = useState({});

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));

    if (students != null) {
      let student = {};

      for (let i = 0; i < students.length; i++) {
        if (students[i].id === props.student) {
          student = students[i];
          setSettings(student.settings);
          break;
        }
      }

      let endpoint =
        "https://aanlyn.nukleus.co.za/server/api/books/fetch-student.php";

      endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

      axios
        .post(endpoint, {
          token: student["token"],
          student_id: student["id"],
        })
        .then(function (response) {
          if (response.data.status === "success") {
            setBooks(response.data.books);

            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [props.student]);

  function handleSubjectSelect(newSubjectFilter) {
    setSubjectFilter(newSubjectFilter);
  }

  function handleModuleSelect(newModuleFilter) {
    setModuleFilter(newModuleFilter);
  }

  function filterBooks() {
    let filteredBooks = [...books];

    if (subjectFilter !== "") {
      filteredBooks = filteredBooks.filter((book) => {
        return book.subject === subjectFilter;
      });
    }

    if (moduleFilter !== 0) {
      filteredBooks = filteredBooks.filter((book) => {
        return parseInt(book.module) === parseInt(moduleFilter);
      });
    }

    return filteredBooks;
  }

  return (
    <div className="bg-light min-h-100vh">
      <NavigationBar selectStudent={props.selectStudent} />

      <Container fluid>
        <h1 className="text-center">
          {subjectFilter.toUpperCase()} BOEKE{" "}
          {moduleFilter === 0 ? "" : ` IN MODULE ${moduleFilter}`}
        </h1>
        <hr />

        <Row className="mt-4">
          <Col md={3}>
            <div className="bg-white w-100 p-4 mb-4 border rounded">
              <h5 className="mb-2">Sorteer Vakke</h5>

              <Form>
                <Form.Group controlId="subjectSelectInput">
                  <Form.Control
                    as="select"
                    size="lg"
                    onChange={(event) => {
                      handleSubjectSelect(event.target.value);
                    }}
                    custom
                  >
                    <option value="">Alle Vakke</option>
                    {subjects.map((subject, index) => {
                      return (
                        <option key={index} value={subject}>
                          {subject}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>

            <div className="bg-white w-100 p-4 border rounded">
              <h5 className="mb-2">Sorteer Modules</h5>

              <Form>
                <Form.Group controlId="moduleSelectInput">
                  <Form.Control
                    as="select"
                    size="lg"
                    onChange={(event) => {
                      handleModuleSelect(parseInt(event.target.value));
                    }}
                    custom
                  >
                    <option value="0">Alle Modules</option>
                    <option value="1">Module 1</option>
                    <option value="2">Module 2</option>
                    <option value="3">Module 3</option>
                    <option value="4">Module 4</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>

            <hr className="my-4" />
          </Col>

          <Col md={9}>
            {loading ? (
              <div className="my-5 py-5 text-center">
                <Spinner animation="border" variant="success" size="lg" />
              </div>
            ) : filterBooks(books).length === 0 ? (
              <h2 className="my-5 text-center">Geen boeke beskikbaar nie!</h2>
            ) : (
              <Row>
                {filterBooks(books).map((book) => {
                  return (
                    <BookCard
                      key={book.book_id}
                      book={book}
                      setBook={props.setBook}
                      settings={settings}
                    />
                  );
                })}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BookLibraryScreen;
