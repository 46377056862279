import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import Logo from "../components/Logo";
import StudentManagerStrip from "../components/StudentManagerStrip";

import axios from "axios";
import jwt_decode from "jwt-decode";

function AccountManagerScreen() {
  const [input, setInput] = useState({
    email: "",
    password: "",
    success: "",
    error: "",
  });
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));
    if (students != null) {
      setStudents(students);
    }
  }, []);

  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let error_message = "";

    if (input.email.length === 0) {
      error_message =
        "Onvoltooide vorm, verskaf asseblief die student se e-posadres!";
    }

    if (input.email.length === 0) {
      error_message =
        "Onvoltooide vorm, verskaf asseblief die student se wagwoord!";
    }

    if (error_message.length === 0) {
      let endpoint =
        "https://aanlyn.nukleus.co.za/server/api/students/auth/authenticate.php";

      endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

      axios
        .post(endpoint, {
          email: input.email,
          password: input.password,
        })
        .then(function (response) {
          if (response.data.status === "success") {
            let decoded = jwt_decode(response.data.token);
            let newStudent = {
              id: decoded.id,
              full_name: decoded.full_name,
              grade: decoded.grade,
              token: response.data.token,
              settings: {
                performance: "standard",
                passcode: false,
                theme: "light",
                smart_bookmark: false,
              },
            };

            let students = JSON.parse(localStorage.getItem("students"));

            if (students != null) {
              let existing = false;

              for (let i = 0; i < students.length; i++) {
                if (students[i].id === newStudent.id) {
                  existing = true;
                  break;
                }
              }

              if (!existing) {
                students.push(newStudent);
              }
            } else {
              students = [newStudent];
            }

            localStorage.setItem("students", JSON.stringify(students));

            setStudents(students);

            setInput({
              email: "",
              password: "",
              success: "Leerder suksesvol ingeteken. Lekker leer!",
              error: "",
            });
          } else {
            if (response.data.error === "Invalid: Email") {
              setInput({
                ...input,
                error:
                  "E-pos nie gevind nie, verskaf asseblief 'n geregistreerde e-posadres!",
              });
            } else if (response.data.error === "Invalid: Password") {
              setInput({
                ...input,
                error: "Verkeerde wagwoord, probeer asseblief weer!",
              });
            } else {
              setInput({
                ...input,
                error: "Onverwagte fout, probeer asseblief weer!",
              });
            }
          }

          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch(function (error) {
          console.log(error);
          setInput({
            ...input,
            error: "Onverwagte fout, probeer asseblief weer!",
          });
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
    } else {
      setInput({ ...input, error: error_message });
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }

  function handleRefresh(refresh_student) {
    setLoading(true);

    let initialSettings = {};
    if (refresh_student.settings !== undefined) {
      initialSettings = {
        performance: refresh_student.settings.performance,
        passcode: refresh_student.settings.passcode,
        theme: refresh_student.settings.theme,
        smart_bookmark: refresh_student.settings.smart_bookmark,
      };
    } else {
      initialSettings = {
        performance: "standard",
        passcode: false,
        theme: "light",
        smart_bookmark: false,
      };
    }

    let endpoint = "https://aanlyn.nukleus.co.za/server/api/students/fetch.php";

    endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

    axios
      .post(endpoint, {
        token: refresh_student.token,
        student_id: refresh_student.id,
      })
      .then(function (response) {
        if (response.data.status === "success") {
          let newStudent = {
            id: response.data.student.student_id,
            full_name:
              response.data.student.first_name +
              " " +
              response.data.student.last_name,
            grade: response.data.student.grade,
            token: response.data.student.token,
            settings: initialSettings,
          };

          let students = JSON.parse(localStorage.getItem("students"));

          let newStudents = students.filter((s) => {
            return s.id !== refresh_student.id;
          });

          if (newStudents != null) {
            newStudents.push(newStudent);
          } else {
            newStudents = [newStudent];
          }

          localStorage.setItem("students", JSON.stringify(newStudents));

          setStudents(newStudents);

          setInput({
            ...input,
            success: "Leerder suksesvol hernu!",
          });

          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      })
      .catch(function (error) {
        console.log(error);

        setInput({
          ...input,
          error: "Onverwagte fout, probeer asseblief weer!",
        });

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  }

  function handleLogout(remove_id) {
    let students = JSON.parse(localStorage.getItem("students"));

    let newStudents = students.filter((student) => {
      return student.id !== remove_id;
    });

    if (newStudents.length === 0) {
      localStorage.removeItem("students");
    } else {
      localStorage.setItem("students", JSON.stringify(newStudents));
    }

    setStudents(newStudents);
  }

  return (
    <Container className="p-4" fluid>
      <Row>
        <Col md={2}>{/* SPACER */}</Col>
        <Col md={8}>
          <div className="mb-5 pb-3 border-bottom text-center">
            <Logo size="logo-md" />
          </div>

          <div className="mb-5 d-flex justify-content-center justify-content-md-between align-items-center flex-wrap">
            <h1 className="text-center text-md-left">Leerder Bestuurder</h1>

            <Button
              as={Link}
              to="/"
              variant="outline-secondary"
              className="px-5 rounded-pill"
            >
              GAAN TERUG
            </Button>
          </div>

          <Jumbotron className="mb-4 p-4 bg-white">
            <div className="w-50 w-md-100 mx-auto">
              <h2>Teken In</h2>
              <hr />

              {input.success.length > 0 ? (
                <h5 className="my-4 text-success text-center">
                  {input.success}
                </h5>
              ) : null}

              {input.error.length > 0 ? (
                <h5 className="my-4 text-danger text-center">{input.error}</h5>
              ) : null}

              <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Control
                    type="email"
                    className="rounded-pill"
                    placeholder="E-POSADRES"
                    name="email"
                    value={input.email}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Control
                    type="password"
                    className="rounded-pill"
                    placeholder="WAGWOORD"
                    name="password"
                    value={input.password}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>

                <div className="mt-4 d-flex justify-content-between align-item-center">
                  <Button
                    variant="success"
                    className="rounded-pill"
                    type="submit"
                    disabled={loading}
                  >
                    TEKEN IN
                  </Button>

                  {loading ? (
                    <Spinner
                      className="mt-1"
                      animation="border"
                      variant="success"
                    />
                  ) : null}
                </div>
              </Form>

              <hr className="my-4" />

              <p className="text-muted text-center text-md-left">
                Hierdie aanmeldingsvorm voeg slegs 'n bestaande leerder se
                rekening op hierdie toestel by. Vir die bestuur van
                studenterekeninge, gaan na die{" "}
                <a
                  href="https://aanlyn.nukleus.co.za/account"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nukleus Aanlyn Skool Webtuiste
                </a>{" "}
                toe.
              </p>
            </div>
          </Jumbotron>

          {students.map((student) => {
            return (
              <StudentManagerStrip
                key={student.id}
                student={student}
                refresh={handleRefresh}
                logout={handleLogout}
              />
            );
          })}
        </Col>
        <Col md={2}>{/* SPACER */}</Col>
      </Row>
    </Container>
  );
}

export default AccountManagerScreen;
