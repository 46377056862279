import React from "react";

import Button from "react-bootstrap/Button";

import { GrSelection } from "react-icons/gr";

function ReaderSideBar(props) {
  return (
    <div className="py-4 px-2 bg-white shadow rounded reader-side-bar d-none d-md-block">
      <Button
        variant="outline-secondary"
        className="my-1"
        onClick={() => {
          props.togglePageSelector(true);
          props.toggle(false);
        }}
      >
        <GrSelection />
      </Button>
    </div>
  );
}

export default ReaderSideBar;
