import React from "react";
import { useHistory } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import VideoThumbnail from "react-video-thumbnail";

function VideoCard(props) {
  const history = useHistory();

  return (
    <Col md={4} className="mb-5">
      <Card className="shadow h-100 overflow-hidden video-card">
        {props.settings.performance === "standard" ? (
          <VideoThumbnail
            videoUrl={props.video.video_url}
            width={1920}
            height={1080}
          />
        ) : null}

        <Card.Body className="text-center">
          <Card.Title>{props.video.title}</Card.Title>
          <Card.Text>
            {props.video.subject} - Module {props.video.module}
          </Card.Text>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between align-items-center mb-4 text-center bg-white border-0">
          <Button
            variant="outline-success"
            className="mt-2 px-5 rounded-pill"
            onClick={() => {
              props.setVideo(props.video);
              history.push("/watch");
              window.scrollTo(0, 0);
            }}
            block
          >
            KYK OPNAME
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default VideoCard;
