import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
  GrNext,
  GrPrevious,
  GrZoomIn,
  GrZoomOut,
  GrRotateRight,
  GrRotateLeft,
} from "react-icons/gr";

function ReaderPageButtons(props) {
  function handlePageCounterChange(e) {
    const newPage = e.target.value;

    if (newPage !== "" && newPage !== 0 && newPage <= props.pageCount) {
      props.setCurrentPage(parseInt(newPage));
    } else {
      props.setCurrentPage(1);
    }
  }

  return (
    <div className="py-3 px-5 bg-white shadow rounded reader-action-bar d-none d-md-block">
      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.prev}
        disabled={props.currentPage <= 1}
      >
        <GrPrevious />
      </Button>

      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.next}
        disabled={props.currentPage >= props.pageCount}
      >
        <GrNext />
      </Button>

      <div className="mx-4 d-inline">|</div>

      <div className="d-inline">
        <span className="text-muted">Bladsy</span>
        <Form.Control
          type="number"
          className="mx-2 d-inline page-number-input"
          id="page-number"
          name="pageCounter"
          value={props.currentPage}
          onChange={handlePageCounterChange}
          min={1}
          max={props.pageCount}
          onFocus={(e) => {
            e.target.select();
          }}
        />
        <span className="text-muted">van {props.pageCount}</span>
      </div>

      <div className="mx-4 d-inline">|</div>

      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.zoomIn}
        disabled={props.zoom === 2.0}
      >
        <GrZoomIn />
      </Button>

      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.zoomOut}
        disabled={props.zoom === 1.0}
      >
        <GrZoomOut />
      </Button>

      <div className="mx-2 d-inline">{/* SPACER */}</div>

      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.turnLeft}
      >
        <GrRotateLeft />
      </Button>

      <Button
        variant="outline-secondary"
        className="mx-1"
        onClick={props.turnRight}
      >
        <GrRotateRight />
      </Button>
    </div>
  );
}

export default ReaderPageButtons;
