import React from "react";
import { useHistory } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function BookCard(props) {
  const history = useHistory();

  return (
    <Col md={4} className="mb-5">
      <Card className="shadow h-100">
        {props.settings.performance === "standard" ? (
          <Card.Img
            src={props.book.cover_img_url}
            variant="top"
            className="mt-5 mb-4 mx-auto rounded book-cover"
          />
        ) : null}

        <Card.Body className="text-center">
          <Card.Title>{props.book.title}</Card.Title>
          <Card.Text>
            {props.book.subject} - Module {props.book.module}
          </Card.Text>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between align-items-center mb-4 text-center bg-white border-0">
          <Button
            variant="outline-success"
            className="px-5 rounded-pill"
            onClick={() => {
              props.setBook(props.book);
              history.push("/read");
              window.scrollTo(0, 0);
            }}
            block
          >
            LEES BOEK
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default BookCard;
