import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import Logo from "../components/Logo";
import StudentCard from "../components/StudentCard";

function AccountSelectionScreen(props) {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));
    if (students != null) {
      setStudents(students);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Container className="p-4 vh-100" fluid>
      <Row>
        <Col md={2}>{/* SPACER */}</Col>
        <Col md={8}>
          <div className="mb-5 pb-3 border-bottom text-center">
            <Logo size="logo-md" />
          </div>

          {loading ? (
            <div className="my-5 py-5 text-center">
              <Spinner animation="border" variant="success" size="lg" />
            </div>
          ) : (
            <>
              {students.length === 0 ? (
                <div className="mb-5 text-center">
                  <h1>Welkom by Nukleus Aanlyn</h1>
                  <p>
                    Om te begin, klik op die knoppie "Bestuur Leerders"
                    hieronder om aan te meld met u leerderbesonderhede.
                  </p>
                </div>
              ) : (
                <Row className="mb-5">
                  {students.map((student) => {
                    return (
                      <StudentCard
                        key={student.id}
                        student={student}
                        selectStudent={props.selectStudent}
                      />
                    );
                  })}
                </Row>
              )}

              <div className="text-center">
                <Button
                  as={Link}
                  to="/account-manager"
                  variant="outline-secondary"
                  className="mb-5 px-5 w-md-100 rounded-pill"
                >
                  BESTUUR LEERDERS
                </Button>
              </div>
            </>
          )}
        </Col>
        <Col md={2}>{/* SPACER */}</Col>
      </Row>
    </Container>
  );
}

export default AccountSelectionScreen;
