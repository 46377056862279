import React from "react";
import { useHistory } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

function StudentCard(props) {
  const history = useHistory();

  return (
    <Col md={4} className="mb-2 mx-auto">
      <Jumbotron className="mb-4 p-4 d-flex justify-content-between flex-wrap align-items-center bg-white shadow rounded">
        <div className="d-flex justify-content-around align-items-center">
          <Image
            src={`https://avatars.dicebear.com/api/initials/${props.student.full_name}.svg`}
            className="mr-4 profile-img-sm rounded-circle"
          />

          <div>
            <h4 className="m-0 p-0">{props.student.full_name}</h4>
            <p className="m-0 p-0 text-muted">Graad {props.student.grade}</p>
          </div>
        </div>

        {props.student.settings === undefined ? (
          <p className="mt-4 text-center w-100 text-danger">
            Herlaai eers hierdie leerder!
          </p>
        ) : (
          <Button
            variant="outline-success"
            className="px-5 rounded-pill mt-4 btn-block"
            onClick={() => {
              props.selectStudent(props.student.id);
              history.push("/book-library");
            }}
          >
            KIES LEERDER
          </Button>
        )}
      </Jumbotron>
    </Col>
  );
}

export default StudentCard;
