import React from "react";
import { Link } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

function ReaderNavbar(props) {
  return (
    <>
      <Navbar
        bg="white"
        expand="lg"
        variant="light"
        fixed="top"
        className="shadow d-none d-md-flex"
      >
        <Navbar.Brand>{props.book.title}</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto"></Nav>

          <Button
            as={Link}
            to="/book-library"
            variant="outline-secondary"
            className="rounded-pill"
          >
            TERUG NA BOEKE
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <div className="mt-5 pt-5 d-none d-md-flex">{/* SPACER */}</div>
    </>
  );
}

export default ReaderNavbar;
