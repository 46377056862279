import { useHistory } from "react-router-dom";

function ProtectedRoute(props) {
  const history = useHistory();

  if (props.student === 0) {
    history.push("/");
    return null;
  } else {
    return props.children;
  }
}

export default ProtectedRoute;
