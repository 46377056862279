import React from "react";

import Alert from "react-bootstrap/Alert";

function DevelopmentModeMessage() {
  if (process.env.NODE_ENV === "development") {
    return (
      <Alert className="dev-message" variant="danger">
        DEVELOPMENT MODE
      </Alert>
    );
  }

  return null;
}

export default DevelopmentModeMessage;
