import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import NavigationBar from "../components/NavigationBar";
import ClassStrip from "../components/ClassStrip";

import axios from "axios";

function OnlineClassScreen(props) {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));

    if (students != null) {
      let student = {};

      for (let i = 0; i < students.length; i++) {
        if (students[i].id === props.student) {
          student = students[i];
          break;
        }
      }

      let endpoint =
        "https://aanlyn.nukleus.co.za/server/api/online-classes/fetch-student.php";

      endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

      axios
        .post(endpoint, {
          token: student["token"],
          student_id: student["id"],
        })
        .then(function (response) {
          console.log(response);
          if (response.data.status === "success") {
            setClasses(response.data.classes);

            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [props.student]);

  return (
    <div className="bg-light min-vh-100">
      <NavigationBar selectStudent={props.selectStudent} />

      <Container fluid>
        <Row>
          <Col md={2}>{/* SPACER */}</Col>

          <Col md={8}>
            <h1 className="text-center">AANLYN KLASSE</h1>
            <hr />

            {loading ? (
              <div className="my-5 py-5 text-center">
                <Spinner animation="border" variant="success" size="lg" />
              </div>
            ) : classes.length === 0 ? (
              <h2 className="my-5 text-center">Geen klasse beskikbaar nie!</h2>
            ) : (
              <div className="mt-4">
                {classes.map((online_class) => {
                  return (
                    <ClassStrip
                      key={online_class.class_id}
                      class={online_class}
                      setClass={props.setClass}
                      student={props.student}
                    />
                  );
                })}
              </div>
            )}
          </Col>

          <Col md={2}>{/* SPACER */}</Col>
        </Row>
      </Container>
    </div>
  );
}

export default OnlineClassScreen;
