import React from "react";

import Image from "react-bootstrap/Image";

function Logo(props) {
  return (
    <Image
      src="./media/Logo-2.png"
      alt="Nukleus Onderwys Logo"
      className={props.size}
      fluid
    />
  );
}

export default Logo;
