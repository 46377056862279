import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import NavigationBar from "../components/NavigationBar";

function OnlineClassScreen(props) {
  const [student, setStudent] = useState(0);
  const [performance, setPerformance] = useState("standard");

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));

    if (students != null) {
      let student = {};

      for (let i = 0; i < students.length; i++) {
        if (students[i].id === props.student) {
          student = students[i];
          setStudent(student);
          break;
        }
      }

      setPerformance(student.settings.performance);
    }
  }, [props.student]);

  const handlePerformanceChange = (value) => {
    let students = JSON.parse(localStorage.getItem("students"));

    let newStudents = students.filter((s) => {
      return s.id !== student.id;
    });

    let newStudent = {
      id: student.id,
      full_name: student.full_name,
      grade: student.grade,
      token: student.token,
      settings: {
        performance: value,
        passcode: student.settings.passcode,
        theme: student.settings.theme,
        smart_bookmark: student.settings.bookmark,
      },
    };

    if (newStudents != null) {
      newStudents.push(newStudent);
    } else {
      newStudents = [newStudent];
    }

    localStorage.setItem("students", JSON.stringify(newStudents));

    setPerformance(value);
  };

  return (
    <div className="bg-light min-vh-100">
      <NavigationBar selectStudent={props.selectStudent} />

      <Container fluid>
        <Row>
          <Col md={2}>{/* SPACER */}</Col>

          <Col md={8}>
            <h1 className="text-center word-break">REKENINGINSTELLINGS</h1>
            <hr />

            <div className="bg-white w-100 p-4 mb-4 border rounded">
              <h5>Programprestasie</h5>
              <p className="text-muted">
                Kies of die program standaard of eenvoudig moet hardloop,
                eenvoudig verhoog die spoed van die webtuiste.
              </p>

              <Form.Check
                type="radio"
                name="performance"
                value="standard"
                label="Standaard"
                checked={performance === "standard"}
                onChange={(e) => handlePerformanceChange(e.currentTarget.value)}
              />

              <Form.Check
                type="radio"
                name="performance"
                value="simple"
                label="Eenvoudig"
                checked={performance === "simple"}
                onChange={(e) => handlePerformanceChange(e.currentTarget.value)}
              />
            </div>

            <div className="bg-white w-100 p-4 mb-4 border rounded">
              <h5>
                Rekeningwagwoord{" "}
                <span className="text-danger small">(Kom Binnekort)</span>
              </h5>
              <p className="text-muted">
                Stel 'n unieke wagwoord in wat ingevoer moet word nadat u u
                rekening gekies het.
              </p>

              <Form>
                <Form.Check
                  disabled
                  type="switch"
                  label="Wagwoord"
                  id="push-notifications"
                />

                <hr />

                <Form.Group>
                  <Form.Label>Wagwoord (Indien aktief)</Form.Label>
                  <Form.Control disabled type="password" />
                </Form.Group>
              </Form>
            </div>

            <div className="bg-white w-100 p-4 mb-4 border rounded">
              <h5>
                Kleur Tema{" "}
                <span className="text-danger small">(Kom Binnekort)</span>
              </h5>
              <p className="text-muted">Kies 'n kleurtema vir die app.</p>

              <Form>
                <Form.Check
                  disabled
                  type="radio"
                  label="Lig"
                  id="light-theme"
                  checked
                />
                <Form.Check
                  disabled
                  type="radio"
                  label="Donker"
                  id="dark-theme"
                />
              </Form>
            </div>

            <div className="bg-white w-100 p-4 mb-4 border rounded">
              <h5>
                Slim Boekmerk{" "}
                <span className="text-danger small">(Kom Binnekort)</span>
              </h5>
              <p className="text-muted">
                Gaan voort waar u opgehou het die laaste keer dat u 'n boek
                gelees het.
              </p>

              <Form>
                <Form.Check
                  disabled
                  type="switch"
                  label="Slim Boekmerk"
                  id="smart-bookmark"
                />
              </Form>
            </div>

            <hr />

            <p className="text-muted small">
              Nukleus Aanlyn Web Toep Weergawe 1.5.0
            </p>
          </Col>

          <Col md={2}>{/* SPACER */}</Col>
        </Row>
      </Container>
    </div>
  );
}

export default OnlineClassScreen;
