import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { GrClose } from "react-icons/gr";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ReaderPageSelector(props) {
  const [file, setFile] = useState(props.file);
  const [pageCount, setPageCount] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setPageCount(numPages);
  }

  function onFileChange(url) {
    setFile({ url });
  }

  function onPasswordHandler(unlock) {
    unlock(props.password);
  }

  return (
    <div className="p-4 bg-white shadow rounded reader-side-section">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="d-inline m-0 p-0">Kies Bladsy</h4>
        <Button
          variant="outline-secondary"
          onClick={() => {
            props.toggleSideBar(true);
            props.toggle(false);
          }}
        >
          <GrClose />
        </Button>
      </div>
      <hr />

      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onFileChange={onFileChange}
        onPassword={onPasswordHandler}
        loading={
          <div className="mt-5 text-center">
            <Spinner animation="border" variant="success" />
          </div>
        }
      >
        {Array.from(new Array(pageCount), (el, index) => (
          <div
            onClick={() => {
              props.setPage(index + 1);
              window.scrollTo(0, 0);
            }}
            className="cursor-pointer shadow-sm"
          >
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          </div>
        ))}
      </Document>
    </div>
  );
}

export default ReaderPageSelector;
