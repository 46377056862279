import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import ReaderNavbar from "../components/ReaderNavbar";
import ReaderSideBar from "../components/ReaderSideBar";
import ReaderActionBar from "../components/ReaderActionBar";
import ReaderPageSelector from "../components/ReaderPageSelector";

import { GrNext, GrPrevious } from "react-icons/gr";

function ReaderScreen(props) {
  const [file, setFile] = useState({
    url: props.book.pdf_url,
  });
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(1.0);
  const [rotation, setRotation] = useState(0);

  const [showSideBar, setShowSideBar] = useState(true);
  const [showPageSelector, setShowPageSelector] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

  const prevPage = useCallback(() => {
    if (currentPage > 1) {
      changePage(-1);
    }
  }, [currentPage]);

  const nextPage = useCallback(() => {
    if (currentPage < pageCount) {
      changePage(1);
    }
  }, [currentPage, pageCount]);

  const zoomIn = useCallback(() => {
    if (zoom < 2.0) {
      changeZoom(0.1);
    }
  }, [zoom]);

  const zoomOut = useCallback(() => {
    if (zoom > 1.0) {
      changeZoom(-0.1);
    }
  }, [zoom]);

  const turnLeft = useCallback(() => {
    changeRotation(-90);
  }, []);

  const turnRight = useCallback(() => {
    changeRotation(90);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      const { key } = event;

      if (key === "ArrowLeft") {
        prevPage();
      }
      if (key === "ArrowRight") {
        nextPage();
      }

      if (key === "i") {
        zoomIn();
      }
      if (key === "o") {
        zoomOut();
      }

      if (key === "[") {
        turnLeft();
      }
      if (key === "]") {
        turnRight();
      }
    },
    [nextPage, prevPage, turnLeft, turnRight, zoomIn, zoomOut]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  function changePage(offset) {
    setCurrentPage((prevPage) => prevPage + offset);
    window.scrollTo(0, 0);
  }

  function changeZoom(offset) {
    setZoom((currentZoom) => currentZoom + offset);
  }

  function changeRotation(offset) {
    setRotation((currentRotation) => currentRotation + offset);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setPageCount(numPages);
    setCurrentPage(1);
  }

  function onFileChange(url) {
    setFile({ url });
  }

  function onPasswordHandler(unlock) {
    unlock(props.book.pdf_password);
  }

  return (
    <Container className="bg-light min-h-100vh" fluid>
      <ReaderNavbar book={props.book} />
      <div className="d-block d-md-none">
        <h5 className="pt-4 text-center">{props.book.title.toUpperCase()}</h5>
      </div>

      {showSideBar === true ? (
        <ReaderSideBar
          toggle={setShowSideBar}
          togglePageSelector={setShowPageSelector}
        />
      ) : null}

      {showPageSelector === true ? (
        <ReaderPageSelector
          toggle={setShowPageSelector}
          toggleSideBar={setShowSideBar}
          setPage={setCurrentPage}
          file={file}
          password={props.book.pdf_password}
        />
      ) : null}

      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onFileChange={onFileChange}
        rotate={rotation}
        onPassword={onPasswordHandler}
        loading={
          <div className="mt-5 text-center">
            <Spinner animation="border" variant="success" />
          </div>
        }
      >
        <Page pageNumber={currentPage} scale={zoom} />
      </Document>

      <div className="pb-4 d-flex d-md-none justify-content-between align-items-center">
        <Button
          as={Link}
          to="/book-library"
          variant="outline-secondary"
          className="rounded-pill"
        >
          TERUG NA BOEKE
        </Button>

        <div>
          <Button
            variant="outline-secondary"
            className="mx-1"
            onClick={prevPage}
            disabled={currentPage <= 1}
          >
            <GrPrevious />
          </Button>

          <Button
            variant="outline-secondary"
            className="mx-1"
            onClick={nextPage}
            disabled={currentPage >= pageCount}
          >
            <GrNext />
          </Button>
        </div>
      </div>

      <ReaderActionBar
        currentPage={currentPage}
        pageCount={pageCount}
        prev={prevPage}
        next={nextPage}
        zoom={zoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        turnLeft={turnLeft}
        turnRight={turnRight}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  );
}

export default ReaderScreen;
