import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import ViewerNavbar from "../components/ViewerNavbar";
import VideoCard from "../components/VideoCard";

import axios from "axios";

function ReaderScreen(props) {
  const videoSource =
    props.video.vimeo_stream_link === "" ? props.video.video_url : "";

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const [settings, setSettings] = useState({});

  useEffect(() => {
    let students = JSON.parse(localStorage.getItem("students"));

    if (students != null) {
      let student = {};

      for (let i = 0; i < students.length; i++) {
        if (students[i].id === props.student) {
          student = students[i];
          setSettings(student.settings);
          break;
        }
      }

      let endpoint =
        "https://aanlyn.nukleus.co.za/server/api/videos/fetch-student.php";

      endpoint = "https://nos-cors-anywhere.herokuapp.com/" + endpoint;

      axios
        .post(endpoint, {
          token: student["token"],
          student_id: student["id"],
        })
        .then(function (response) {
          console.log(response);
          if (response.data.status === "success") {
            setVideos(response.data.videos);

            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [props.video, props.student]);

  function filterVideos() {
    let filteredVideos = [...videos];

    if (props.video.subject !== "") {
      filteredVideos = filteredVideos.filter((video) => {
        return video.subject === props.video.subject;
      });
    }

    if (props.video.module !== 0) {
      filteredVideos = filteredVideos.filter((video) => {
        return parseInt(video.module) === parseInt(props.video.module);
      });
    }

    filteredVideos = filteredVideos.filter((video) => {
      return parseInt(video.video_id) !== parseInt(props.video.video_id);
    });

    return filteredVideos;
  }

  return (
    <Container fluid>
      <ViewerNavbar video={props.video} />

      <div className="d-block d-md-none">
        <Button
          as={Link}
          to="/video-library"
          variant="outline-secondary"
          className="rounded-pill w-100 mt-4"
        >
          TERUG NA OPNAMES
        </Button>

        <hr />

        <h5 className="text-center">{props.video.title.toUpperCase()}</h5>
      </div>

      <div className="video-container">
        {videoSource === "" ? (
          <iframe
            src={
              "https://player.vimeo.com/video/" +
              props.video.vimeo_stream_link +
              "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            }
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            className="video-player"
            title="G4m2engW5D5&amp;ndash; Collective nouns.mp4"
          ></iframe>
        ) : (
          <video
            key={videoSource}
            width="75%"
            height="auto"
            className="react-player d-block video-player"
            controls
            controlsList="nodownload"
          >
            <source src={videoSource} type="video/mp4" />
            Fout! Herlaai die bladsy asseblief.
          </video>
        )}
      </div>

      <div className="mt-5 w-75 w-md-100 mx-auto bg-white">
        <h2 className="d-none d-md-block text-center">
          MEER {props.video.subject.toUpperCase()} OPNAMES{" "}
          {props.video.module === 0 ? "" : ` IN MODULE ${props.video.module}`}
        </h2>
        <h2 className="d-block d-md-none text-center">MEER OPNAMES</h2>
        <hr />

        {loading ? (
          <div className="my-5 py-5 text-center">
            <Spinner animation="border" variant="success" size="lg" />
          </div>
        ) : filterVideos(videos).length === 0 ? (
          <h2 className="my-5 text-center">Geen opnames beskikbaar nie!</h2>
        ) : (
          <Row className="mt-4">
            {filterVideos(videos).map((video) => {
              return (
                <VideoCard
                  key={video.video_id}
                  video={video}
                  setVideo={props.setVideo}
                  settings={settings}
                />
              );
            })}
          </Row>
        )}
      </div>
    </Container>
  );
}

export default ReaderScreen;
