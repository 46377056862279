import React from "react";
import { Link, useHistory } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

import Logo from "../components/Logo";

function NavigationBar(props) {
  const history = useHistory();

  return (
    <>
      <Navbar
        expand="lg"
        bg="white"
        variant="light"
        fixed="top"
        className="shadow"
      >
        <Navbar.Brand>
          <Logo size="logo-sm" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto mt-4 mt-md-0">
            <Nav.Link as={Link} to="/book-library">
              Boeke
            </Nav.Link>
            <Nav.Link as={Link} to="/video-library">
              Opnames
            </Nav.Link>
            <Nav.Link as={Link} to="/online-classes">
              Aanlyn Klasse
            </Nav.Link>
            <Nav.Link as={Link} to="#" disabled>
              Assesseerings
            </Nav.Link>
            <Nav.Link as={Link} to="#" disabled>
              Taaklys
            </Nav.Link>
            <Nav.Link as={Link} to="/settings">
              Rekeninginstellings
            </Nav.Link>
          </Nav>

          <Button
            variant="outline-secondary"
            className="rounded-pill w-md-100 my-4 my-md-0"
            onClick={() => {
              props.selectStudent(0);
              history.push("/");
            }}
          >
            VERANDER LEERDER
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <div className="mt-5 pt-5 pb-4">{/* SPACER */}</div>
    </>
  );
}

export default NavigationBar;
