import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";

import AccountSelectionScreen from "./screens/AccountSelectionScreen";
import AccountManagerScreen from "./screens/AccountManagerScreen";

import BookLibraryScreen from "./screens/BookLibraryScreen";
import ReaderScreen from "./screens/ReaderScreen";

import VideoLibraryScreen from "./screens/VideoLibraryScreen";
import ViewerScreen from "./screens/ViewerScreen";

import OnlineClassScreen from "./screens/OnlineClassScreen";

import SettingsScreen from "./screens/SettingsScreen";

import DevelopmentModeMessage from "./components/DevelopmentModeMessage";

function App() {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [book, setBook] = useState({});
  const [video, setVideo] = useState({});

  return (
    <div className="m-0 p-0">
      <Router>
        <Switch>
          <Route path="/" exact>
            <AccountSelectionScreen selectStudent={setSelectedStudent} />
          </Route>

          <Route path="/account-manager" exact>
            <AccountManagerScreen />
          </Route>

          <Route path="/book-library" exact>
            <ProtectedRoute student={selectedStudent}>
              <BookLibraryScreen
                student={selectedStudent}
                selectStudent={setSelectedStudent}
                setBook={setBook}
              />
            </ProtectedRoute>
          </Route>

          <Route path="/read" exact>
            <ProtectedRoute student={selectedStudent}>
              <ReaderScreen book={book} />
            </ProtectedRoute>
          </Route>

          <Route path="/video-library" exact>
            <ProtectedRoute student={selectedStudent}>
              <VideoLibraryScreen
                student={selectedStudent}
                selectStudent={setSelectedStudent}
                setVideo={setVideo}
              />
            </ProtectedRoute>
          </Route>

          <Route path="/watch" exact>
            <ProtectedRoute student={selectedStudent}>
              <ViewerScreen
                student={selectedStudent}
                video={video}
                setVideo={setVideo}
              />
            </ProtectedRoute>
          </Route>

          <Route path="/online-classes" exact>
            <ProtectedRoute student={selectedStudent}>
              <OnlineClassScreen
                student={selectedStudent}
                selectStudent={setSelectedStudent}
              />
            </ProtectedRoute>
          </Route>

          <Route path="/settings" exact>
            <ProtectedRoute student={selectedStudent}>
              <SettingsScreen
                student={selectedStudent}
                selectStudent={setSelectedStudent}
              />
            </ProtectedRoute>
          </Route>

          <Route path="*">
            <AccountSelectionScreen selectStudent={setSelectedStudent} />
          </Route>
        </Switch>
      </Router>

      <DevelopmentModeMessage />
    </div>
  );
}

export default App;
