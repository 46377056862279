import React from "react";

import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

function StudentManagerStrip(props) {
  return (
    <Jumbotron className="mb-4 p-4 d-flex justify-content-between flex-wrap align-items-center bg-white shadow rounded">
      <div className="d-flex justify-content-around align-items-center">
        <Image
          src={`https://avatars.dicebear.com/api/initials/${props.student.full_name}.svg`}
          className="mr-4 profile-img-sm rounded-circle"
        />

        <div>
          <h4 className="m-0 p-0">{props.student.full_name}</h4>
          <p className="m-0 p-0 text-muted">Graad {props.student.grade}</p>
        </div>
      </div>

      <div class="m-0 p-0">
        <Button
          variant="outline-success"
          className="mr-2 rounded-pill mt-4 w-md-100 mt-md-0"
          onClick={() => props.refresh(props.student)}
        >
          HERLAAI
        </Button>

        <Button
          variant="outline-danger"
          className="rounded-pill mt-4 w-md-100 mt-md-0"
          onClick={() => props.logout(props.student.id)}
        >
          TEKEN UIT
        </Button>
      </div>
    </Jumbotron>
  );
}

export default StudentManagerStrip;
